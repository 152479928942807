input {
  border: 1px solid #aeaeae;
  border-radius: 5px;
  color: #717174;
  font-size: 1.2em;
  padding: .5em .75em;
}

button {
  margin-left: 1em;
  display: inline-block;
  background: #16709E;
  font-size: 1.2em;
  color: #fff;
  text-transform: uppercase;
  padding: .4em .7em;
  border: 1px solid #002060;
  border-radius: 4px;
}

body, button, .search_status {
  font-family: 'Open Sans', sans-serif;
}

.search_status {
  color: #717174;
  padding: 0 1em;
}

.search_status h2 {
  color: #004879;
  font-size: 1.2rem;
  margin-top: 0;
}

h1 {
  color: #16709E;
  font-size: 1.4rem;
}

.status h3 {
  font-weight: normal;
}

.status_form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.status_result {
  margin-top: 1rem;
}

.status_result p {
  margin-bottom: 0;
}

.status_result.status {
  display: flex;
  flex-flow: column wrap;
}

.card {
  background-clip: border-box;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  margin-bottom: 1em;
  padding: 1em;
}

.status_legend section {
  display: flex;
  flex-flow: column wrap;
}

.status_legend ul {
  color: #50636d;
  font-size: 1.0em;
  line-height: 1.1;
  list-style-type: square;
  margin: 0;
}

.completed {
  color: #3dad00;
}

.in_progress, .presentation_process {
  color: #004879;
}

.canceled {
  color: #ad0000;
}

.hold {
  color: #e27c00;
}

.loaderContainer {
  padding-left: 1em;
  display: inline-block;
  vertical-align: top;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader {
  margin: auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #909090;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
